<template>
  <p>{{ $t('greetingMessage') }}</p>
</template>

<script>
export default {
  name: 'Members',
  data() {
    return {
      //
    }
  },
}
</script>
